import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Container } from "../layoutComponents"
import { Info } from "../info"

import Logo from "../../images/royco-logo.svg"

import { FaPhoneAlt } from "react-icons/fa"
import { SiMailDotRu } from "react-icons/si"
import { IoMdArrowDropdown } from "react-icons/io"

const device = {
  sm: "18em",
  md: "48em",
}

const HeaderContact = styled.div`
  background: var(--clr-dark);
  padding: 1em 0 1em 0;
`

const HeaderContactWrapper = styled.div`
  color: var(--txt-light);
  display: flex;
  justify-content: space-between;
  align-items: center;

  p,
  li,
  a {
    font-size: var(--fs-sm);
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;

    & > * + * {
      margin-left: 25px;
    }
    li {
      display: flex;
      align-items: center;
      color: var(--txt-light);

      & > * + * {
        margin-left: 2px;
      }

      a {
        color: var(--txt-light);
        text-decoration: none;
      }

      &:nth-child(2) {
        @media screen and (max-width: ${device.md}) {
          display: none;
        }
      }
    }
  }
`

const Header = styled.header`
  background: var(--clr-light);
  box-shadow: var(--shadow-bottom);
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999; */
  transition: top 0.5s ease-out;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
`

const LogoContainer = styled.div`
  img {
    height: 50px;
  }
`

const Nav = styled.nav`
  /* z-index allowing mobile menu to stack ontop of serviceFull */
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const NavList = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;

  & > * + * {
    margin-left: 2em;
  }

  @media screen and (max-width: ${device.md}) {
    display: ${({ nav }) => (nav ? "flex" : "none")};
    align-items: center;
    position: absolute;
    top: 136.4px;

    left: 0;
    right: 0;
    flex-direction: column;
    width: 100%;
    background: var(--clr-light);
    box-shadow: var(--shadow-bottom);
    border-radius: var(--br);
    padding: 1em 2em 2em;

    & > * + * {
      margin-left: 0;
      margin-top: 15px;
    }
  }
`

const Company = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: var(--txt-light);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${device.sm}) {
    display: none;
  }
`

const Dropdown = styled.li`
  /* hover display only on desktop */
  @media screen and (min-width: ${device.md}) {
    ul {
      position: absolute;
      z-index: 1000;
      display: none;
      opacity: 1;
      margin: 0;
      padding: 2em 2em 2em 1em;
      list-style-type: none;
      background: var(--clr-dark);

      li {
        a {
          color: var(--txt-light);
        }
      }
    }

    &:hover {
      ul {
        display: flex;
        flex-direction: column;
      }
    }
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: var(--fs-sm);
  font-weight: var(--fw-button);
  color: var(--txt-dark);
  letter-spacing: 0.1em;
  transition: all 0.25s linear;

  // aligning text with icon
  display: flex;
  align-items: center;
  gap: 2px;

  :hover,
  :focus {
    opacity: 0.7;
  }

  @media screen and (max-width: ${device.md}) {
    font-size: var(--fs-b);
  }
`

const Burger = styled.div`
  display: none;
  cursor: pointer;

  div {
    width: 25px;
    height: 3px;
    margin: 5px;
    background-color: var(--clr-accent);

    :nth-child(1) {
      transform: ${({ nav }) =>
        nav ? "rotate(-45deg) translate(-6px, 5px)" : ""};
    }
    :nth-child(2) {
      opacity: ${({ nav }) => (nav ? "0" : "")};
    }
    :nth-child(3) {
      transform: ${({ nav }) =>
        nav ? "rotate(45deg) translate(-6px, -5px)" : ""};
    }
  }

  @media screen and (max-width: ${device.md}) {
    transform: scale(1);
    display: block;
    z-index: 10000;
  }
`

export default function HeaderBasic() {
  const [nav, navOpen] = useState(false)

  function toggleMenu() {
    navOpen(!nav)
  }

  return (
    <div>
      <HeaderContact>
        <Container>
          <HeaderContactWrapper>
            <Company to="/">
              <p className="bold upper">{Info.name}</p>
              <p>ECRA/ESA Licence #7012919</p>
            </Company>
            <ul>
              <li>
                <FaPhoneAlt />
                <a className=" italics" href="tel: 905-580-4559">
                  {Info.tel1}
                </a>
              </li>
            </ul>
          </HeaderContactWrapper>
        </Container>
      </HeaderContact>
      <Header>
        <Container>
          <HeaderWrapper>
            <LogoContainer>
              <Link to="/">
                <img src={Logo} alt="Royco Electric Ltd." />
              </Link>
            </LogoContainer>
            <Nav>
              <Burger nav={nav} onClick={toggleMenu}>
                <div />
                <div />
                <div />
              </Burger>
              <NavList nav={nav}>
                <li>
                  <StyledLink to="/about">about</StyledLink>
                </li>
                <Dropdown>
                  <StyledLink to="/services">
                    services
                    <IoMdArrowDropdown size={20} />
                  </StyledLink>
                  <ul>
                    <li>
                      <StyledLink to="/services/residential">
                        residential
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/services/commercial">
                        commercial
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/services/industrial">
                        industrial
                      </StyledLink>
                    </li>
                  </ul>
                </Dropdown>
                <Dropdown>
                  <StyledLink to="/projects">
                    projects
                    <IoMdArrowDropdown size={20} />
                  </StyledLink>
                  <ul>
                    <li>
                      <StyledLink to="/projects/residential">
                        residential
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/projects/commercial">
                        commercial
                      </StyledLink>
                    </li>

                    <li>
                      <StyledLink to="/projects/industrial">
                        industrial
                      </StyledLink>
                    </li>
                  </ul>
                </Dropdown>
                <li>
                  <StyledLink to="/testimonials">testimonials</StyledLink>
                </li>
                <li>
                  <StyledLink to="/contact">contact</StyledLink>
                </li>
              </NavList>
            </Nav>
          </HeaderWrapper>
        </Container>
      </Header>
    </div>
  )
}
