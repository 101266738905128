import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Info } from "../info"
import { Container, Flex } from "../layoutComponents"

import { FaPhoneAlt, FaRegClock } from "react-icons/fa"
import { SiMailDotRu } from "react-icons/si"
import { IoLocationSharp } from "react-icons/io5"

import AaronTonnerWebSolutionsLogo from "../../images/aaron-tonner-web-solutions-logo.svg"

import Logo from "../../images/royco-logo.svg"
import Licence from "../../images/licence.svg"
import Facebook from "../../images/socials/facebook.svg"
import Instagram from "../../images/socials/instagram.svg"

const device = {
  sm: "38em",
  lg: "57em",
}

const FooterWrapper = styled.footer`
  padding: 7em 0 1em 0;
  background: var(--clr-light-secondary);
  color: var(--txt-dark);

  @media screen and (max-width: 450px) {
    padding: 4em 0;
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 50px;
  }
  & > * + * {
    margin-left: 5px;
  }
`

const LicenceContainer = styled.div`
  img {
    width: 100%;
  }
`

const Socials = styled.div`
  display: flex;

  & > * + * {
    margin-left: 15px;
  }

  div {
    display: flex;

    & > * + * {
      margin-left: 5px;
    }
  }

  img {
    width: 25px;
  }
`

const FlexContact = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 5px;
  }

  a {
    color: var(--clr-accent);
  }
`

const FlexItem = styled.div`
  ul {
    list-style-type: none;
    padding: 0;
    margin: var(--spacer) 0;

    li {
      a {
        color: var(--txt-dark);
        font-weight: var(--fw-500);
      }
    }
  }
`

const Copyright = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;

  @media screen and (max-width: ${device.lg}) {
    flex-direction: column;
    align-items: flex-start;
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media screen and (max-width: ${device.sm}) {
      flex-direction: column;
    }

    & > * + * {
      margin-left: 1em;

      @media screen and (max-width: ${device.sm}) {
        margin-left: 0;
      }
    }

    li {
      a {
        text-transform: capitalize;
        color: var(--txt-dark);
        font-weight: var(--fw-500);
      }
    }
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  text-transform: capitalize;
  color: var(--txt-dark);
  font-weight: var(--fw-500);

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 0.7;
  }
`

const Author = styled.div`
  color: var(--txt-dark-secondary);

  div {
    display: flex;
    gap: 0.5em;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
  }

  a {
    display: inline;
    color: var(--txt-dark-secondary);
  }

  img {
    width: 150px;
  }
`

export default function Footer() {
  return (
    <div className="spacing-lg">
      <FooterWrapper className="spacing">
        <Container className="spacing-lg">
          <Socials>
            <p className="italics upper">follow us:</p>
            <div>
              <a
                target="_popup"
                href="https://www.facebook.com/pages/category/Contractor/Royco-Electric-Ltd-110172949855188/"
              >
                <img src={Facebook} alt="royco electric facebook" />
              </a>
              <a
                target="_popup"
                href="https://www.instagram.com/toronto_electrical/?hl=en"
              >
                <img src={Instagram} alt="royco electric instagram" />
              </a>
            </div>
          </Socials>
          <Flex>
            <FlexItem>
              <p className="heading">contact us</p>
              <ul>
                <li>
                  <FlexContact>
                    <FaPhoneAlt className="accent" />
                    <a className=" italics" href="tel: 905-580-4559">
                      {Info.tel1}
                    </a>
                  </FlexContact>
                </li>
                <li>
                  <FlexContact>
                    <FaRegClock className="accent" />
                    <p>{Info.hours}</p>
                  </FlexContact>
                </li>
              </ul>
              <ul>
                <li>{Info.street1}</li>
                <li>{Info.city1}</li>
                <li>Canada</li>
              </ul>
            </FlexItem>
            <FlexItem>
              <p className="heading">quick links</p>
              <ul>
                <li>
                  <StyledLink to="/">home</StyledLink>
                </li>
                <li>
                  <StyledLink to="/about">about us</StyledLink>
                </li>
                <li>
                  <StyledLink to="/services">our services</StyledLink>
                </li>
                <li>
                  <StyledLink to="/projects">our projects</StyledLink>
                </li>
                <li>
                  <StyledLink to="/testimonials">testimonials</StyledLink>
                </li>
                <li>
                  <StyledLink to="/contact">contact us</StyledLink>
                </li>
              </ul>
            </FlexItem>
            <FlexItem className="spacing">
              <p className="heading">service areas</p>
              <ul>
                <li>GTA, ON</li>
                <li>Muskoka, ON</li>
              </ul>
            </FlexItem>
          </Flex>
          <hr />
          <Copyright>
            <div>
              <LogoContainer>
                <img src={Logo} alt="royco electric ltd." />
                <h3 className="heading bold">{Info.name}</h3>
              </LogoContainer>
              <LicenceContainer>
                <img src={Licence} alt="electrican contractor toronto" />
              </LicenceContainer>
            </div>
            <ul>
              <li>Copyright&#169; 2022</li>
              <li>{Info.name}</li>
              <li>All Rights Reserved</li>
              <li>
                <a
                  target="_popup"
                  href="https://www.roycoelectric.com/terms-of-use"
                >
                  Terms of use
                </a>
              </li>
              <li>
                <a
                  target="_popup"
                  href="https://www.roycoelectric.com/privacy-policy"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </Copyright>
          <hr />
        </Container>
        <Author>
          <Container>
            <div>
              <p>website by: </p>
              <a target="_popup" href="https://www.aarontonner.com">
                <img src={AaronTonnerWebSolutionsLogo} alt="" />
              </a>
            </div>
          </Container>
        </Author>
      </FooterWrapper>
    </div>
  )
}
